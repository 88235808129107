<template>
  <div>
    <b-table
      id="spaceTable"
      :items="items"
      :fields="fields"
      borderless
      class="mb-0 h-100"
    >
      <template #head()="data">
        <div class="column-heading pl-3">{{ data.label }}</div>
      </template>
      <template #head(pastAnalysis)="">
        <div class="column-heading">Past 10 days analysis</div>
      </template>
      <template #cell(objectives)="data">
        <div class="d-flex pl-3">
          <div class="dots" :style="{ background: data.item.color }"></div>
          <div class="column-heading">
            {{ data.item.objectives }}
          </div>
        </div>
      </template>
      <template #cell(metricsDelivery)="data">
        <div class="metrics">
          {{ data.item.metricsDelivery }}
        </div>
      </template>
      <template #cell(variance)="data">
        <div class="metrics">
          <img
            v-if="data.item.variance.direction === 'up'"
            src="@/assets/Icons/green-arrow.svg"
            class="arrow-image"
          />
          <img v-else src="@/assets/Icons/red-arrow.svg" class="arrow-image" />
          {{ data.item.variance.value }}
        </div>
      </template>
      <template #cell(pastAnalysis)="data">
        <div style="overflow: hidden">
          <ComparedLine
            height="50"
            :lineData="data.item.past10daysanalysis"
            :categories="data.item.categories"
            :colors="[`${data.item.color}`]"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ComparedLine from "@/components/SavedPlans/ComparedLine.vue";
export default {
  name: "PerformanceTable",
  components: {
    ComparedLine,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          key: "objectives",
          sortable: false,
          tdClass: "align-middle text-center",
        },
        { key: "metricsDelivery", sortable: false, tdClass: "align-middle" },
        { key: "variance", sortable: false, tdClass: "align-middle" },
        { key: "pastAnalysis", sortable: false, tdClass: "align-middle" },
      ],
    };
  },
};
</script>

<style scoped>
.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}

.metrics {
  font-family: ProximaNovaSemiBold;
  font-size: 14px;
  text-align: center;
  color: #222a37;
}
.dots {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 14px;
}
>>> .table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border: none;
}
>>> .table > :not(:last-child) > :last-child > * {
  border-bottom: 2px solid rgba(154, 175, 212, 0.5);
}
</style>
