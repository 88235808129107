<template>
  <div>
    <line-chart :boxshadow="true" :chartData="lineChartData" />
  </div>
</template>
<script>
import LineChart from "@/components/Chart/LineChart.vue";
export default {
  name: "ComparedLine",
  components: {
    LineChart,
  },
  props: {
    lineData: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: "80",
    },
    categories: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "200",
    },
  },
  data() {
    return {
      lineChartData: {
        colors: this.colors,
        width: this.width,
        height: this.height,
        series: this.lineData,
        title: {
          text: "",
          style: {
            display: "none",
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: this.categories,
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: "transparent",
          labels: {
            enabled: false,
          },
          minorTickLength: 0,
          tickLength: 0,
        },
        yAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: "",
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
::v-deep .highcharts-root {
  padding: 0px;
}
</style>
