<template>
  <div>
    <div class="value">{{ value }} {{ unit }}</div>
    <div
      class="d-flex align-items-center"
      :class="[type === 'upward' ? 'green' : 'red']"
    >
      <img
        v-if="type === 'upward'"
        src="@/assets/Icons/green-arrow.svg"
        class="arrow-image"
      />
      <img v-else src="@/assets/Icons/red-arrow.svg" class="arrow-image" />
      <span class="percentage">{{ percentage }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ComparedStats",
  props: {
    value: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    percentage: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.value {
  font-size: 28px;
  font-family: ProximaNovaBold;
  color: #313846;
}
.percentage {
  font-size: 16px;
  font-family: ProximaNovaBold;
}
.arrow-image {
  width: 16px;
  height: 16px;
}
.green {
  color: #55ea76;
}
.red {
  color: #ff5a5a;
}
</style>