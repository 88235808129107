import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();
export class HealthMonitor {
    constructor() {}
    getHealthMonitorDashboards(currentPage, perPage, startDate, endDate, clientId, type, campaignName) {
        const uri = `health-monitor-module/healthmonitor?orgNumber=${sessionStorage.getItem('subId')}&page=${currentPage}&size=${perPage}&startDate=${startDate}&endDate=${endDate}&clientId=${clientId}&type=${type}&campaignName=${campaignName}`
        return apiHelper.get(uri)
    }
    getObjective(orgNumber, campaignId, objective, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/objectives?orgNumber=${orgNumber}&campaignId=${campaignId}&objective=${objective}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getChannelWiseMetrics(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getchannel-wise-metrics?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getSalesPerformance(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/sales-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getYesterdayPerformance(orgNumber, campaignId, requiredMetrics, startDate, endDate, requiredChannel, requiredAdtype) {
        const uri = `health-monitor-module/healthmonitor/getyesterday-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&startDate=${startDate}&endDate=${endDate}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getDiplayMetrics(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getmetrics-for-display?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getSocialMetrics(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getmetrics-for-social?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&typeOfMetrics=social&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getAnalytics(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/v1/web-analytics?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&typeOfMetrics=webAnalytics&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getSocialInsights(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/v1/social-insights?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&typeOfMetrics=socialInsights&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getTableData(campaignId, orgNumber, metrics, requiredChannel, requiredAdtype, startDate, endDate, page, size) {
        const uri = `health-monitor-module/healthmonitor/getsummary?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${metrics}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}&page=${page}&size=${size}`
        return apiHelper.get(uri)
    }
    getAdTypeWiseMetric(orgNumber, campaignId, requiredMetrics, metrics, requiredAdtype, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getadtype-wise-metrics?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&requiredChannel=${metrics}&requiredAdtype=${requiredAdtype}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getChannelPerformance(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getchannel-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getMonthWisePerformance(orgNumber, campaignId, requiredMetrics, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getmonthwise-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&dateFrom=${startDate}&dateTo=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getChannelSalesPerformace(orgNumber, campaignId, requiredMetrics, dateFrom, dateTo, requiredChannel, requiredAdtype) {
        const uri = `health-monitor-module/healthmonitor/getchannel-sales-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&dateFrom=${dateFrom}&dateTo=${dateTo}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getRegionWisePerformance(orgNumber, campaignId, requiredMetrics, requiredChannel, requiredAdtype, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getregionwise-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&basedOn=region&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getDeviceTypeUsage(orgNumber, campaignId, requiredMetrics, requiredChannel, requiredAdtype, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getdevicewise-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&basedOn=device&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getAdGroupTableData(data, campaignId, orgNumber, metrics, requiredChannel, requiredAdtype, startDate, endDate, page, size) {
        const uri = `health-monitor-module/healthmonitor/getsummary?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${metrics}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}&page=${page}&size=${size}`
        return apiHelper.post(uri, data)
    }
    getTopCampaign(orgNumber, campaignId, requiredMetrics, requiredChannel, requiredAdtype, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getdevicewise-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&basedOn=campaignname&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    getTopAdgroup(orgNumber, campaignId, requiredMetrics, requiredChannel, requiredAdtype, startDate, endDate) {
        const uri = `health-monitor-module/healthmonitor/getdevicewise-performance?orgNumber=${orgNumber}&campaignId=${campaignId}&requiredMetrics=${requiredMetrics}&requiredChannel=${requiredChannel}&requiredAdtype=${requiredAdtype}&basedOn=adgroupname&startDate=${startDate}&endDate=${endDate}&type=${sessionStorage.getItem('campaignType')}`
        return apiHelper.get(uri)
    }
    testApi(){
        const uri = `https://run.mocky.io/v3/0bffe736-9bb2-4b6a-9763-2ca404739801`
        return apiHelper.testApi(uri)
    }
}