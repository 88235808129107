<template>
  <div>
    <ColumnChart :chartData="columnChartData" :disableDataLabel="false" />
  </div>
</template>

<script>
import ColumnChart from "@/components/Chart/ColumnChart.vue";
export default {
  name: "ComparedColumn",
  components: {
    ColumnChart,
  },
  props: {
    columnData: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: "200",
    },
    height: {
      type: String,
      default: "80",
    },
  },
  data() {
    return {
      columnChartData: {
        width: this.width,
        height: this.height,
        data: this.columnData,
        title: {
          text: "",
        },
        subtitle: {},
        categories: [],
        xAxis: {
          categories: [],
          crosshair: true,
          lineWidth: 0,
          minorGridLineWidth: 0,
          lineColor: "transparent",
          labels: {
            enabled: false,
          },
          minorTickLength: 0,
          tickLength: 0,
        },
        yAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: "",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#ffdd7c"],
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            borderRadiusBottomRight: 25,
            borderRadiusBottomLeft: 25,
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
          formatter: function() {
            const x = this.x;
            if (this.series.name) {
              return ` ${this.series.name}
              : <b>
            ${this.series.data[x].options.y}
              </b>`;
            } else {
              return this.series.data[x].options.y;
            }
          },
        },
      },
    };
  },
};
</script>

<style scoped>
::v-deep .highcharts-root {
  padding: 0px;
}
</style>
