<template>
  <b-modal
    hide-footer
    no-close-on-backdrop
    hide-header
    no-fade
    v-model="modalShow"
    size="lg"
  >
    <div class="">
      <div class="w-100">
        <div class="row p-5">
          <div class="col p-0 welcome ml-4 mt-4 align-start">
            <img v-if="action === 'delete'" src="@/assets/group-22.svg" alt />
            <img
              v-else
              src="@/assets/group-21.png"
              alt
              class="welcome--image"
            />
          </div>
          <div class="col px-5">
            <p class="title">
              <span v-if="action === 'delete'">Delete plan?</span>
              <span v-else-if="action === 'add'">Plan details</span>
              <span v-else-if="action === 'share'">Share plan details</span>
            </p>
            <p v-if="action === 'delete'" class="subtitle">
              Hey Aditya, are you sure to delete the plan from the system?
            </p>
            <div v-else>
              <b-form-input
                class="input-wrapper mb-5"
                v-model="description"
              ></b-form-input>
              <div class="w-100">
                <w-dropdown
                  :options="action === 'add' ? planList : userList"
                  :placeHolder="'Select'"
                  :labelText="
                    action === 'add' ? 'Plan saved in' : 'Share plan to'
                  "
                  class=""
                  @input="selectDropdown($event)"
                ></w-dropdown>
              </div>
            </div>

            <div
              class="d-flex w-100 m-0"
              :class="[
                action === 'delete'
                  ? 'justify-content-start'
                  : 'justify-content-end',
              ]"
            >
              <button
                :class="{
                  'bg-red': action === 'delete',
                  'btn-effect-disabled': disabled,
                }"
                class="btn-effect"
                @click="handleSave"
              >
                <span v-if="action === 'delete'" class="btn-text"
                  >Yes, delete</span
                >
                <span v-else class="btn-text">Save Plan</span>
                <i
                  class="fas fa-arrow-right ml-5 pl-4 d-flex"
                  style="align-items: center"
                ></i>
              </button>
            </div>
            <div v-if="action === 'delete'" @click="close" class="change-mind">
              No, I changed my mind
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
export default {
  name: "Plan",
  components: {
    "w-dropdown": Dropdown,
  },
  data() {
    return {
      modalShow: false,
      planList: [
        {
          text: "Personal Space",
          id: "Personal Space",
        },
        {
          text: "Shared Folder",
          id: "Shared Folder",
        },
      ],
      userList: [
        {
          text: "Harish Natarajan",
          id: "user",
          iconHTML: '<i class="fa fa-user"></i>',
        },
      ],
      description: "description",
      action: "",
      selectedDropdown: "",
    };
  },
  computed: {
    disabled() {
      if (this.action === "delete") {
        return false;
      } else {
        if (this.selectedDropdown === "Select") {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    open(action) {
      this.modalShow = true;
      this.action = action;
      if (this.action === "share") {
        this.description = "Diwali Campaign";
      }
    },
    close() {
      this.modalShow = false;
    },
    handleSave() {
      if(this.disabled){
        return
      }
      this.$emit("updatePlan", this.action);
      this.close();
    },
    selectDropdown(val) {
      this.selectedDropdown = val;
    },
  },
};
</script>
<style>
.modal.show .modal-dialog{
margin-top: 0px;
}
</style>
<style scoped>
input {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  color: #222a37;
  font-size: 16px;
  font-family: ProximaNovaBold;
}
.input-wrapper {
  height: 60px;
  font-size: 16px;
  padding-left: 21px;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
}
.title {
  margin-top: 70px;
  font-size: 32px;
  font-family: ProximaNovaBold;
  line-height: 1.56;
  color: #222a37;
}
.subtitle {
  width: 239px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  line-height: 1.38;
  color: #9aafd4;
  margin-bottom: 24px;
}
.bg-red {
  background-color: #ff5a5a !important;
}
.change-mind {
  margin-top: 26px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #050505;
  text-decoration-line: underline;
}
.btn-effect {
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 208px !important;
  height: 60px !important;
  margin-top: 24px !important;
  object-fit: contain;
}

.btn-effect-disabled {
  opacity: 0.2;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}
</style>