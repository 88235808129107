<template>
  <div class="chart-container">
    <p class="d-none">{{ chartData.series }}</p>
    <bar-chart :options="chartOptions" ref="barChart"></bar-chart>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { abbreviateNumber } from "js-abbreviation-number";
export default {
  name: "BarChart",
  components: {
    barChart: Chart,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    annotation: {
      type: String,
      default: "",
    },
  },
  beforeUpdate() {
    this.chartOptions.series = this.chartData.series;
    this.chartOptions.plotOptions.series.dataLabels.annotation = this.annotation;
  },
  created() {
    this.chartOptions.series = this.chartData.series;
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: this.chartData.height,
          scrollablePlotArea: {
            minHeight: this.chartData.scrollableHeight
              ? this.chartData.scrollableHeight
              : 0,
            opacity: 1,
          },
        },
        colors: this.chartData.colors,
        title: this.chartData.title,
        xAxis: this.chartData.xAxis,
        yAxis: this.chartData.yAxis,
        tooltip: this.chartData.tooltip,
        plotOptions: {
          bar: this.chartData.plotOptions.bar,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
            pointPadding: 0.4,
            dataLabels: {
              enabled: true,
              annotation: this.annotation,
              formatter: function(data) {
                return (
                  abbreviateNumber(this.y, 1, {
                    symbols: ["", "k", "M", "B", "T", "P", "E"],
                  }) + data.annotation
                );
              },
            },
          },
        },
        legend: this.chartData.legend,
        credits: this.chartData.credits,
        series: this.chartData.series,
      },
    };
  },
};
</script>
