<template>
  <div>
    <multiselect
      ref="multiselect"
      v-model="value"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Pick some"
      label="name"
      track-by="name"
      :preselect-first="false"
      :show-labels="false"
      :openDirection="'below'"
      @input="change($event)"
      @close="close($event)"
      @open="disableOptions"
      class="multiselectDropdown"
    >
      <template slot="selection" slot-scope="{ values, isOpen }"
        ><span
          class="multiselect__single"
          v-if="values.length &amp;&amp; !isOpen || isOpen"
          >{{ values.length }} selected</span
        ></template>
      <template slot="option" slot-scope="props">
        <div class="option__desc" :for="props.option.name + props.option.value">
          <input
            class="form-check-input"
            type="checkbox"
            :id="props.option.name + props.option.value"
            :disabled="props.option.$isDisabled"
            :checked="
              value.find((o) => o.name === props.option.name) !== undefined
            "
          />
          <span class="option__title ml-4">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    maximum: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: () => [],
    },
    initialSelectedOptions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      value: [],
    };
  },
  methods: {
    updateData(data){
      this.value = data
      this.change(data)
    },
    change(event) {
      if (event.length === this.maximum) {
        this.value = event;
        this.disableOptions();
      } else {
        for (var j = 0; j < this.options.length; j++) {
          this.options[j].$isDisabled = false;
        }
      }
    },
    disableOptions() {
      this.options.forEach((element, index) => {
        if (
          this.value.filter((obj) => obj.name == element.name)
            .length === 0
        ) {
          this.options[index].$isDisabled = true;
        }else{
          this.options[index].$isDisabled = false
        }
      });
    },
    close(event) {
      if (event.length === this.maximum) {
        this.value = event;
      } else {
        this.value = this.initialSelectedOptions;
        this.change(this.initialSelectedOptions);
      }
      for (var i = 0; i < this.value.length; ) {
        if (this.compare(this.value[i].name).length === 0) {
          i = this.value.length;
          this.$emit("selectedOptions", this.value);
        } else {
          i++;
        }
      }
    },
    compare(value) {
      return this.initialSelectedOptions.filter((obj) => obj.name === value);
    },
  },
  created() {
    this.value = this.initialSelectedOptions;
    this.change(this.value);
  },
};
</script>
<style>
.multiselectDropdown.multiselect {
  min-height: 55px;
  width: 230px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 8px 0 #c9ced5;
  border-radius: 3px;
  background: #fff;
  z-index: 9;
}
.multiselect__single {
  width: auto;
  margin: 0;
  font-size: 16px;
  /* background: #; */
  color: #050505;
}
.multiselect__select {
  width: 36px;
  height: 100%;
}
.multiselectDropdown .multiselect__tags {
  min-height: 0px;
  font-family: ProximaNovaRegular;
  font-weight: 600;
  padding: 0px;
  border: none;
}
.multiselect__select:before {
  border-color: #050505 transparent transparent;
}
.multiselect__content-wrapper {
  background-color: #fff;
  box-shadow: 0px 5px 5px 0px #c9ced5;
  top: 65px;
}

.multiselectDropdown .multiselect__option--selected,
.multiselect__option--group {
  color: #050505;
}
.multiselectDropdown .multiselect__option--selected{
  background: #eff5ff !important;
}
.multiselectDropdown .multiselect__option--disabled{
  background: #ededed !important;
}
.multiselect__option {
  display: flex;
  line-height: 6px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #313846;
  font-family: ProximaNovaRegular;
}

.multiselectDropdown .multiselect__input {
  background: transparent;
  width: 0% !important;
}
.multiselect__placeholder {
  padding-left: 5px;
  padding-top: 0px;
  margin: 0px;
  font-size: 13px;
}
.option__desc {
  display: flex;
  align-items: center;
}
.checkbox-option {
  height: 24px;
  margin: 0px;
  margin-right: 20px;
  width: 24px;
}

.form-check-input {
  height: 24px;
  position: relative;
  top: 0px;
  margin: 0px;
  margin-right: 10px;
  border-color: #222a37;
}

.form-check-input:checked {
  background-color: #222a37;
  border-color: #222a37;
}
.multiselect__option--selected,
.multiselect__option--group {
  background: #eff5ff !important;
  font-weight: 400;
  cursor: pointer;
  color: #313846;
  font-family: ProximaNovaBold;
}
.multiselect__option:hover {
  background: #eff5ff !important;
  font-family: ProximaNovaBold;
}

.multiselect__option--selected.multiselect__option--highlight > label > span {
  color: #313846;
  opacity: 0.8;
}
.multiselect__option--selected.multiselect__option--highlight {
  color: #313846;
}
.multiselect__option--highlight > label > span {
  opacity: 0.8;
}
.multiselect__option--disabled {
  color: #989898;
}
label {
  cursor: pointer;
}
</style>
