<template>
  <div class="chart-container">
    <donut-chart id="donut" :options="chartOptions"
    ref="donutChart"></donut-chart>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
export default {
  name: "DonutChart",
  components: {
    donutChart: Chart,
  },
  props: {
    // drilldown: {
    //   type: Object,
    //   default: () => {},
    // },
    title: {
      type: String,
      default: "",
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        colors: this.chartData.colors,
        chart: {
          type: "pie",
          style: {
            float: "right",
          },
          // events: {
          //   drilldown: function (e) {
          //     console.log(e, '333333333333');
          //     this.setTitle({
          //       text: e.point.name,
          //     });
          //    this.showLoading('Simulating Ajax ...');

          //               this.hideLoading();
          //               this.addSeriesAsDrilldown(e.point, e.target.options.series);
          //   },
          // },
        },
        title: this.chartData.title,
        tooltip: {
          verticalAlign: "middle",
          floating: true,
        },
        // drilldown: {},
        series: this.chartData.series,
        legend: this.chartData.legend,
        credits: {
          enabled: false,
        },
        plotOptions: this.chartData.plotOptions,
      },
    };
  },
  // mounted() {
  //   // this.chartOptions.title.text = this.title;
  //   this.chartOptions = this.chartData;
  //   this.chartOptions.drilldown = this.drilldown;
  // },
};
</script>

<style scoped>
::v-deep .highcharts-container {
  margin: 0 auto;
}
</style>